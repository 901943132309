body {
    margin: 0;
    padding: 0;
  }
  body .text-center {
    text-align: center;
  }
  body .activity-id {
    font-size: 12px;
    color: #9c9c9c;
    margin-top: 20px;
  }
  body .download-apps {
    margin: 10px 0;
  }
  body .download-apps a {
    font-size: 30px;
    display: inline-block;
  }
  body .btn-special {
    border: 1px solid #d3d3d3;
    background: #f9f9f9;
    color: #000;
    height: 56px;
    width: 300px;
    border-radius: 3px;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.08);
  }
  body .btn-special a.icon {
    font-size: 16px;
    display: block;
    vertical-align: middle;
    line-height: 74px;
  }
  body.layout-mini {
    background: #fafafa;
    display: table;
    height: 100%;
    width: 100%;
  }
  body.layout-mini .container {
    width: 100%;
    max-width: 483px;
    margin: 0 auto;
    position: relative;
    vertical-align: top;
    background: #fff;
    box-shadow: 2px 2px 6px 0 rgba(41, 70, 97, 0.1);
  }
  body.layout-mini .container > .wrapper {
    padding: 70px 90px;
  }
  body.layout-mini .container > div {
    min-height: 250px;
  }
  body.layout-mini .header {
    text-align: center;
    margin-bottom: 25px;
  }
  body.layout-mini .body-content {
    display: table-cell;
    vertical-align: middle;
    text-align: left;
    line-height: 1.25rem;
    padding: 0 40px;
  }
  body.layout-mini .welcome-page {
    text-align: center;
  }
  .login-page .anticon {
    font-size: 20px;
  }
  .login-page .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 45px;
  }
  .login-page .login-form-button {
    width: 100%;
  }
  .login-page .login-form-forgot {
    float: right;
  }
  .login-page .login-form-actions {
    margin-top: 25px;
  }
  .forgot-page .anticon {
    font-size: 20px;
  }
  .forgot-page .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 45px;
  }
  .forgot-page .forgot-form-login {
    float: right;
  }
  .logged-out iframe {
    display: none;
    width: 0;
    height: 0;
  }
  .page-consent .client-logo {
    float: left;
  }
  .page-consent .client-logo img {
    width: 80px;
    height: 80px;
  }
  .page-consent .consent-buttons {
    margin-top: 25px;
  }
  .page-consent .consent-form .consent-scopecheck {
    display: inline-block;
    margin-right: 5px;
  }
  .page-consent .consent-form .consent-description {
    margin-left: 25px;
  }
  .page-consent .consent-form .consent-description label {
    font-weight: normal;
  }
  .page-consent .consent-form .consent-remember {
    padding-left: 16px;
  }
  .grants .page-header {
    margin-bottom: 10px;
  }
  .grants .grant {
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid lightgray;
  }
  .grants .grant img {
    width: 100px;
    height: 100px;
  }
  .grants .grant .clientname {
    font-size: 140%;
    font-weight: bold;
  }
  .grants .grant .granttype {
    font-size: 120%;
    font-weight: bold;
  }
  .grants .grant .created {
    font-size: 120%;
    font-weight: bold;
  }
  .grants .grant .expires {
    font-size: 120%;
    font-weight: bold;
  }
  .grants .grant li {
    list-style-type: none;
    display: inline;
  }
  .grants .grant li:after {
    content: ', ';
  }
  .grants .grant li:last-child:after {
    content: '';
  }
  @media screen and (max-width: 600px) {
    body.layout-mini {
      background: #ffffff;
      display: table;
      height: 100%;
      width: 100%;
    }
    body.layout-mini .container {
      width: 100%;
      max-width: 100%;
      margin: 0 auto;
      position: relative;
      vertical-align: top;
      background: #fff;
      box-shadow: none;
    }
    body.layout-mini .container > .wrapper {
      padding: 0;
    }
    body.layout-mini .container > div {
      min-height: 250px;
    }
  }
  header {
    position: relative;
    background-color: black;
    height: 100%;
    min-height: 25rem;
    width: 100%;
    overflow: hidden;
  }
  header video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }
  header .img-background-login {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    max-height: 100%;
    z-index: 0;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }
  header .mainsecion {
    position: relative;
    z-index: 2;
  }
  header .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.5;
    z-index: 1;
  }
  header .logo {
    position: absolute;
    background: transparent;
    top: 0;
    right: 0;
    bottom: 0;
    left: -20%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 1;
  }
  .card-custom {
    width: 510px;
    border-radius: 2px;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
  }
  .title-header {
    font-family: Moderat;
    font-size: 19px;
    /* font-weight: bold; */
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    margin-bottom: 46px;
    color: #181818;
  }
  .title-header-one {
    font-family: Moderat;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    margin-bottom: 46px;
    color: #181818;
  }
  .content-part {
    padding: 56px;
  }
  .success-message {
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    padding: 0px;
    color: #4d59e0;
  }
  .error-message {
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    padding: 0px;
    color: red;
  }
  label {
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #4d59e0;
  }
  input {
    font-family: RobotoMedium;
    font-size: 14px !important;
    padding-top: 12px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 15px !important;
    border-width: 0 0 2px;
    border-color: #eff1f7;
    border: 0 !important;
    border-radius: 0 !important;
    outline: 0;
    border-bottom: 2px solid #eff1f7 !important;
    color: #181818 !important;
  }
  input:focus {
    border: 0 !important;
    border-radius: 0 !important;
    border-bottom: 2px solid #b7b9bd !important;
    box-shadow: none !important;
  }
  .custom-btn {
    font-family: RobotoMedium;
    background: white;
    border-radius: 2px;
    border: solid 2px #eff1f7;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #4d59e0;
    padding: 13px;
    margin-top: 44px;
    height: 56px;
  }
  .custom-btn:hover {
    background: #eff1f7;
  }
  .form-group {
    margin-bottom: 10px !important;
  }
  form {
    margin: 0;
  }
  .forgot-password {
    text-align: right;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #4d59e0;
  }
  a {
    color: #484BC8;
    background: #EFF1F7;
    padding: 15px;
    border-radius: 50%;
  }
  a:hover {
    color: #d5b8ff;
    background-color: white;
  }
  .login {
    padding-left: 12px;
  }
  .img-clss {
    width: 15px;
    height: 15px;
  }
  .sign-up {
    float: left;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #181818;
  }
  .sign-up-success {
    float: left;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #ffffff;
  }
  .footer-card {
    margin-top: 2%;
    margin-bottom: 2%;
    padding-bottom: 5%;
  }
  .main-signup-header {
    padding-top: 56px;
    padding-left: 56px;
    padding-bottom: 44px;
    padding-right: 56px;
    background-color: #4d59e0;
    border-radius: 2px;
  }
  .sign-up-title-header {
    font-family: Moderat;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #ffffff;
    padding-bottom: 24px;
  }
  .sub-title {
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #ffffff;
  }
  .signup-form-head {
    padding-right: 56px;
    padding-left: 56px;
    padding-bottom: 56px;
  }
  .error-label {
    vertical-align: middle;
    color: #ed614c;
    padding-right: 10px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
  }
  .error-footer-label {
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #ed614c;
  }
  .sign-up-completed-title-header {
    font-family: Moderat;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #ffffff;
    padding-bottom: 43px;
  }
  .sub-completed-title {
    height: auto;
    display: flex;
  }
  .check-bg-round {
    background-color: #404cce;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    margin-right: 16px;
    float: left;
  }
  .check-mark-image {
    object-fit: contain;
  }
  .thank-you-note {
    font-family: RobotoMedium;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #ffffff;
    margin-top: 10px;
  }
  .completed-text {
    width: 278px;
    height: 72px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #ffffff;
    margin-top: 26px;
  }
  .main-signup-completed-header {
    padding-top: 161px;
    padding-left: 56px;
    padding-bottom: 175px;
    padding-right: 56px;
    background-color: #4d59e0;
    border-radius: 2px;
  }
  .password-strangth {
    padding-top: 14px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #19b9c5;
  }
  .two-factor-subheader {
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #181818;
  }
  .twofactor-title-header {
    font-family: Moderat;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: normal;
    color: #181818;
    padding-bottom: 3%;
  }
  .circle-bg-round {
    background-color: #eff1f7;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    margin-right: 16px;
    float: left;
  }
  .base-twofactor {
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    color: #4d59e0;
    padding-top: 9px;
  }
  .number-notes {
    font-family: RobotoMedium;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    color: #181818;
    margin-top: 9px;
  }
  .img-list {
    display: flex;
    margin-top: 8px;
    margin-left: 50px;
  }
  .img-auth {
    float: left;
    padding-right: 15px;
  }
  .img-inside-auth {
    width: 29.8px;
    height: 32px;
  }
  .part-block {
    margin-bottom: 5%;
  }
  .email-confirm-text {
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #008000;
  }
  .email-confirm-part-block {
    height: auto;
    display: flex;
    padding-bottom: 20px;
  }
  .email-check-bg-round {
    background-color: #008000;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    margin-right: 16px;
    float: left;
  }
  .qr-content {
    float: left;
    margin-left: 24px;
    max-width: 30%;
  }
  .qr-decs {
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #181818;
    margin-bottom: 8px;
  }
  .qr-key {
    font-family: RobotoMedium;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    color: #181818;
    max-width: 80%;
  }
  .two-content-part {
    padding: 3%;
  }
  .img-qr {
    float: left;
    height: 90px;
  }
  .label-text {
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #181818;
  }
  .part-block-end {
    margin-bottom: 8px;
  }
  .no-top-margin {
    margin-top: 0px;
  }
  .error-msg {
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #ed614c;
  }
  .input-box {
    position: relative;
  }
  .text-note {
    height: 100px;
  }
  .form-control:disabled,
  .form-control[readonly] {
    background-color: white;
  }
  .img-responsive {
    width: 323px;
    height: 114px;
    object-fit: contain;
  }
  .ant-form-item-children {
    padding-bottom: 32px;
  }
  .password-container {
    position: relative;
  }
  .password-container input[type="password"],
  .password-container input[type="text"] {
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
  }
  .fa-eye,
  .fa-eye-slash {
    position: absolute;
    top: 28%;
    right: 4%;
    cursor: pointer;
    color: #4d59e0;
  }
  #2faInput {
    padding: 5% !important;
  }
  #enable2faCard {
    margin-top: 8%;
  }
  @media screen and (max-width: 520px) {
    .card-custom {
      width: 110%;
    }
    #enable2faCard {
      margin-top: 0;
    }
    .qr-key {
      margin: 0;
    }
    .img-list {
      margin-bottom: 0;
    }
    .two-factor-content {
      zoom: 90%;
    }
    #qrCode {
      zoom: 80%;
    }
  }
  #2faLabel {
    margin: 0 !important;
  }

  .forgetPassword {
    background: transparent !important;
  }
  
  .loader {
    width: 48px;
    height: 48px;
    border: 3px solid #FFF;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  } 
  .loader::after {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 56px;
    height: 56px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-bottom-color: #4d59e0;
  }

  .loader-reset {
    width: 25px;
    height: 25px;
    border: 3px solid #FFF;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  } 
  .loader-reset::after {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-bottom-color: #4d59e0;
  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 